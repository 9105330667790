.slideshow-wrap {
    position: relative;
    .slogan {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-slide-caption;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            font-weight: bold;
            font-size: $font-size-46;
            color: $color-white;
            text-shadow: 0 0 10px $color-black;
            text-align: center;
        }

        a.btn-slideshow {
            background: #356998;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 215px;
            height: 49px;
            margin: 50px auto 0 auto;
            transition: 0.3s;
            h5 {
                font-weight: 600;
                font-size: $font-size-16;
                color: $color-white;
                text-transform: uppercase;
            }
            &:hover {
                background: #C53C2F;
                transition: 0.3s;
            }
        }

        div.center {
            max-width: 750px;
        }
        .icon-reseau {
            position: absolute;
            right: 50px;
            top: 20px;
            padding-top: 50px;
            a {
                position: relative;
                img {
                  transition: 0.3s;
                    &:nth-child(1) {
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        position: absolute;
                        opacity: 0;
                        left: 0;
                    }
                }
                &:hover {
                  img {
                    transition: 0.3s;
                      &:nth-child(1) {
                          opacity: 0;
                      }
                      &:nth-child(2) {
                          opacity: 1;
                      }
                  }
                }
            }
        }
        @media screen and (max-width: 1690px) {

            @media screen and (max-width: 1550px) {

              @media screen and (max-width: 1450px) {

                  .icon-reseau.flex {
                      padding-top: 15px;
                      right: -32%;
                  }
                  @media screen and (max-width: 1320px) {

                      .icon-reseau.flex {
                          padding-top: 5px;
                          right: -23%;
                      }
                      @media screen and (max-width: 1200px) {
                          h2 {
                              font-size: 2.15rem;
                          }
                          a.btn-slideshow {
                              margin: 30px auto 0 auto;
                          }

                          @media screen and (max-width: 1050px) {

                              h2 {
                                  font-size: 2rem;
                              }
                              a.btn-slideshow {
                                  margin: 25px auto 0 auto;
                              }
                              @media screen and (max-width: $size-sm-max) {

                                  .icon-reseau.flex {
                                      padding-top: 0;

                                  }
                                  @media screen and (max-width: $size-xs-max) {

                                      h2 {
                                          font-size: 2.3rem;
                                      }
                                      @media screen and (max-width: 390px) {
                                          h2 {
                                              font-size: 2.15rem;
                                          }
                                          a.btn-slideshow {
                                              width: 170px;
                                          }
                                          @media screen and (max-width: 375px) {
                                              h2 {
                                                  font-size: 2.1rem;
                                              }
                                              @media screen and (max-width: 360px) {
                                                h2 {
                                                    font-size: 2rem;
                                                }

                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
            }
        }
    }

    .slideshow {
      	width: 100%;
      	overflow: hidden;
      	position: relative;
      	.placeholder {
        		width: 100vw;
        		height: auto;
        		display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
      	}
        &.mobile {
            display: none;
        }
      	.slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }
        		img {
        			 width: 100vw!important;
        			 height: auto;
        			 display: block;
        			 position: relative;
        		}
            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
        		}
        		&.active {
          			right: 0;
                left: 0;
          			z-index: 10;
                opacity: 1;
                transition: 1.5s;
        		}
        	}
        	@media screen and (max-width: 480px) {
              &.mobile {
                  display: block;
              }
              &.ecran {
                  display: none;
              }
        	}
      }
}
