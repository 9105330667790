.btn {
    background-color: #356998;
    color: $color-white;
    border: none;
    border-radius: 0;
    padding: 15px;
    height: 52px;
    font-weight: normal;
    font-size: $font-size-16;
    width: 191px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        color: $color-white;
        background-color: #C53C2F;
    }
    @media screen and (max-width: 1500px) {
        width: 180px;
        @media screen and (max-width: 1430px) {
            width: 165px;
            @media screen and (max-width: $size-md-max) {
                width: 145px;
                @media screen and (max-width: 1144px) {
                    width: 120px;
                    @media screen and (max-width: 1024px) {
                        width: 105px;
                        @media screen and (max-width: $size-sm-max) {
                            width: 191px;
                        }
                    }
                }
            }
        }
    }
}
