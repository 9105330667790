footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            .copyright {
                font-weight: 600;
                font-size: $font-size-16;
                color: #91ADC7;
                text-transform: uppercase;
                padding-bottom: 10px;
            }
            .mediaweb {
                a {
                    span {
                        font-weight: 600;
                        font-size: $font-size-16;
                        color: #91ADC7;
                        text-transform: uppercase;
                        transition: 0.3s;
                    }
                    &:hover {
                        span {
                            color: #356998;
                            transition: 0.3s;
                        }
                    }
                }
            }
        }

        .center-side {
            a.link-footer {
                font-weight: 600;
                font-size: $font-size-16;
                color: #91ADC7;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: #356998;
                    transition: 0.3s;
                        
                }
            }
        }

        @media screen and (max-width: 1120px) {
            .center-side {
                a.link-footer.px12 {
                    padding-right: 2.5rem;
                    padding-left: 2.5rem;
                }
                a.link-footer.pr12 {
                    padding-right: 2.5rem;
                }
            }
            @media screen and (max-width: 995px) {
                flex-direction: column-reverse;
                .center-side {
                    padding: 35px 0;
                }
                @media screen and (max-width: $size-xs-max) {
                    .center-side {
                        padding: 25px 0;
                        flex-direction: column;
                        a.link-footer {
                            padding: 5px 0 !important;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 995px) {
        min-height: 200px;
        @media screen and (max-width: $size-xs-max) {
            min-height: 240px;
        }
    }
}
