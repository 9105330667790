.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        top: 82%;
        padding: 0 10%;
        width: 100%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-size:$font-size-60;
            font-weight: bold;
            color: $color-white;
            //text-shadow: 0 0 10px $color-black;
        }
    }
}
@media screen and (max-width: $size-sm-max) {
    .module-header {
        &--caption {
            top: 68% !important;
            padding: 0 5%;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .module-header {
            &--caption {
                top: 85% !important;
            }
        }
    }
}
