// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-intro {
    padding: 75px 5%;
    text-align: center;
    width: 65%;
    margin: auto;
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #356998;
        margin: 0;
        line-height: 1.5;
    }
    @media screen and (max-width: 1550px) {
        width: 75%;
        @media screen and (max-width: $size-md-max) {
            width: 85%;
            @media screen and (max-width: $size-sm-max) {
                width: 100%;
            } 
        }   
    }
}

#section-equipements.accueil {
    padding: 0 5%;
    h3 {
        font-weight: bold;
        font-size: $font-size-32;
        color: #356998;
        text-align: center;
    }
    .section-produits-vedette {
        padding-top: 35px;
        h3 {
            text-align: initial;
        }
    }
}

#section-text-image {
    padding: 0 5% 75px 5%;
    margin: 65px 0 25px 0;
    position: relative;
    .left-side {
        width: 50%;
        .text-box {
            padding-top: 18%;
            padding-left: 16%;
            h3 {
                font-weight: bold;
                font-size: $font-size-36;
                color: #356998;
            }
            .border {
                background: #356998;
                height: 2px;
                width: 138px;
                margin: 5px 0 15px 0;
            }
            p {
                font-weight: normal;
                font-size: $font-size-26;
                color: #909090;
                margin: 0;
                line-height: 1.3;
            }
            a.btn-blue {
                background: #356998;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 215px;
                height: 55px;
                margin: 25px 0 0 0;
                transition: 0.3s;
                h5 {
                    font-weight: 600;
                    font-size: $font-size-16;
                    color: $color-white;
                    text-transform: uppercase;
                }
                &:hover {
                    background: #C53C2F;
                    transition: 0.3s;
                }
            }
        }
    }
    .right-side {
        width: 50%;
    }
    &::before {
        content: '';
        position: absolute;
        width: 1245px;
        height: 760px;
        background: #91ADC724;
        left: 0;
        top: -70px;
        z-index: -1;
    }
    @media screen and (max-width: 1850px) {
        margin: 65px 0 50px 0;
        @media screen and (max-width: 1785px) {
            margin: 65px 0 75px 0;
            @media screen and (max-width: 1680px) {
                margin: 65px 0 115px 0;
                @media screen and (max-width: 1680px) {
                    margin: 65px 0 40px 0;
                    &::before {
                        width: 1125px;
                        height: 650px;
                    }
                    @media screen and (max-width: 1360px) {
                        margin: 65px 0 15px 0;
                        &::before {
                            width: 1000px;
                            height: 600px;
                        }
                        @media screen and (max-width: 1250px) {
                            margin: 65px 0 0 0;
                            &::before {
                                width: 880px;
                                height: 550px;
                            }
                            @media screen and (max-width: 1100px) {
                                flex-direction: column;
                                .left-side {
                                    width: 100%;
                                    margin-bottom: 60px;
                                    .text-box {
                                        padding-top: 5%;
                                        padding-left: 13%;
                                    }
                                }
                                .right-side {
                                    width: 70%;
                                    margin: auto;
                                }
                                &::before {
                                    width: 100%;
                                    height: 800px;
                                }
                                @media screen and (max-width: $size-sm-max) {
                                    .left-side {
                                        padding-left: 0%;
                                        text-align: center;
                                        .icon {
                                            .img-responsive {
                                                margin: auto;
                                            }
                                        }
                                        .text-box {
                                            padding-left: 0;
                                            .border {
                                                margin: 5px auto 15px auto;
                                            }
                                            a.btn-blue {
                                                margin: 25px auto 0 auto;
                                            }
                                        }
                                    }
                                    .right-side {
                                        width: 100%;
                                    }
                                    &::before {
                                        height: 750px;
                                    }
                                    @media screen and (max-width: $size-xs-max) {
                                        &::before {
                                            height: 600px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-image-text {
    padding: 25px 5%;
    .left-side {
        width: 50%;
        padding-right: 35px;
    }
    .right-side {
        width: 50%;
        h3 {
            font-weight: bold;
            font-size: $font-size-32;
            color: #356998;
            padding-bottom: 15px;
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #356998;
            margin: 0;
            line-height: 1.5;
        }
    }
    @media screen and (max-width: 1635px) {
        align-items: center;
        @media screen and (max-width: 1550px) {
            .right-side {
                p {
                    font-size: 0.9rem;
                }
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .left-side {
                    width: 70%;
                    padding: 0 0 35px 0;
                }
                .right-side {
                    width: 70%;
                    text-align: center;
                }
                @media screen and (max-width: $size-sm-max) {
                    .left-side, .right-side {
                        width: 100%;
                    }
                }
            }
        }
    }
}

#section-burst {
    padding: 0 0 0.75rem 0;
    .left-side {
        width: 40%;
        background: #C53C2F24;
        height: 390px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 10%;
        h3 {
            padding-bottom: 65px;
        }
    }
    .right-side {
        width: 60%;
        .bloc {
            background: #C53C2F24;
            height: 187px;
            padding: 0 13%;
            .text {
                width: 50%;
            }
        }
    }
    h3 {
        font-weight: normal;
        font-size: $font-size-26;
        color: #91ADC7;
        text-transform: initial;
    }
    a.btn-blue {
        background: #356998;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 190px;
        height: 50px;
        margin: 25px 0 0 0;
        transition: 0.3s;
        h5 {
            font-weight: 600;
            font-size: $font-size-16;
            color: $color-white;
            text-transform: uppercase;
        }
        &:hover {
            background: #C53C2F;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: 1200px) {
        .left-side {
            padding: 0 6%;
        }
        @media screen and (max-width: 1024px) {
            .left-side {
                padding: 0 5%;
            }
            .right-side {
                .bloc {
                    .text {
                        width: 65%;
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .right-side .bloc {
                    padding: 0 5%;
                }
                @media screen and (max-width: $size-xs-max) {
                    flex-direction: column;
                    .left-side {
                        padding: 50px 5%;
                        width: 100%;
                        height: initial;
                        margin: 0 0 0.75rem 0;
                        h3 {
                            padding-bottom: 0;
                        }
                    }
                    .right-side {
                        width: 100%;
                        .bloc {
                            padding: 50px 5%;
                            flex-direction: column;
                            align-items: initial;
                            height: initial;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* ÉQUIPEMENTS
*******************************************************************************/

#section-vedette {
    padding: 0 5% 75px 5%;
    margin: 65px 0 25px 0;
    position: relative;
    .left-side {
        width: 50%;
        h2 {
            font-weight: bold;
            font-size: $font-size-32;
            color: #356998;
            text-transform: uppercase;
        }
        .text-box {
            padding-top: 20%;
            padding-left: 20%;
            h3 {
                font-weight: bold;
                font-size: $font-size-36;
                color: #356998;
                text-transform: initial;
            }
            .border {
                background: #356998;
                height: 2px;
                width: 138px;
                margin: 5px 0 15px 0;
            }
            h4 {
                font-weight: bold;
                font-size: $font-size-30;
                color: #356998;
                padding-top: 15px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-26;
                color: #909090;
                margin: 0;
                line-height: 1.3;
            }
            a.btn-blue {
                background: #356998;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 215px;
                height: 55px;
                margin: 35px 0 0 0;
                transition: 0.3s;
                h5 {
                    font-weight: 600;
                    font-size: $font-size-16;
                    color: $color-white;
                    text-transform: uppercase;
                }
                &:hover {
                    background: #C53C2F;
                    transition: 0.3s;
                }
            }
        }
    }
    .right-side {
        width: 50%;
    }
    &::before {
        content: '';
        position: absolute;
        width: 1245px;
        height: 760px;
        background: #C53C2F24;
        left: 0;
        top: -70px;
        z-index: -1;
    }
    @media screen and (max-width: 1850px) {
        margin: 65px 0 50px 0;
        @media screen and (max-width: 1785px) {
            margin: 65px 0 75px 0;
            @media screen and (max-width: 1680px) {
                margin: 65px 0 115px 0;
                @media screen and (max-width: 1680px) {
                    margin: 65px 0 40px 0;
                    &::before {
                        width: 1125px;
                        height: 650px;
                    }
                    @media screen and (max-width: 1360px) {
                        margin: 65px 0 15px 0;
                        &::before {
                            width: 1000px;
                            height: 600px;
                        }
                        @media screen and (max-width: 1250px) {
                            margin: 65px 0 0 0;
                            &::before {
                                width: 880px;
                                height: 550px;
                            }
                            @media screen and (max-width: 1100px) {
                                flex-direction: column;
                                .left-side {
                                    width: 100%;
                                    margin-bottom: 60px;
                                    .text-box {
                                        padding-top: 5%;
                                        padding-left: 13%;
                                    }
                                }
                                .right-side {
                                    width: 70%;
                                    margin: auto;
                                }
                                &::before {
                                    width: 100%;
                                    height: 800px;
                                }
                                @media screen and (max-width: $size-sm-max) {
                                    .left-side {
                                        padding-left: 0%;
                                        text-align: center;
                                        .icon {
                                            .img-responsive {
                                                margin: auto;
                                            }
                                        }
                                        .text-box {
                                            padding-left: 0;
                                            .border {
                                                margin: 5px auto 15px auto;
                                            }
                                            a.btn-blue {
                                                margin: 25px auto 0 auto;
                                            }
                                        }
                                    }
                                    .right-side {
                                        width: 100%;
                                    }
                                    &::before {
                                        height: 750px;
                                    }
                                    @media screen and (max-width: $size-xs-max) {
                                        &::before {
                                            height: 600px;
                                        }
                                        .left-side {
                                            .text-box {
                                                padding-top: 15%;
                                                a.btn-blue {
                                                    width: 150px;
                                                    height: 45px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title {
    background: #356998;
    padding: 50px;
    text-align: center;
    h2 {
        font-weight: bold;
        font-size: $font-size-40;
        color: $color-white;
        text-transform: uppercase;
    }
}

section#toolbar {
    padding: 30px 5%;
    ul.menu-filter.filter {
        list-style: none;
        padding-left: 5px;
        li.filter-item {
            button {
                background: none;
                border: none;
                cursor: pointer;
                color: #356998;
                font-weight: 600;
                font-size: $font-size-16;
                padding-right: 50px;
                transition: 0.3s;
                &:hover {
                    color: #C53C2F;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: $size-xs-max){
        ul.menu-filter.filter {
            flex-direction: column;
            padding-left: 0;
            li.filter-item button {
                padding-right: 0;
            }
        }
    }
}

section.gallerie-products.flex {
    padding: 0 5% 100px 5%;
    .section-gallerie.grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 65px 20px;
        //align-items: start;
        //justify-items: center;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: $size-sm-max){
                grid-template-columns: repeat(2, 1fr);
                @media screen and (max-width: $size-xs-max){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        .grid-item {
            .image-side {
                border: 3px solid #356998;
                border-radius: 10px;
                padding: 25px;
                margin-bottom: 25px;
            }
            .text-box {
                h3 {
                    font-weight: bold;
                    font-size: $font-size-24;
                    color: #356998;
                    text-transform: initial;
                }
                h4 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: #356998;
                    padding: 25px 0;
                }
                p {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: #356998;
                    margin: 0;
                    line-height: 1.5;
                }
                a.btn-equipement {
                    background: #356998;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 190px;
                    height: 50px;
                    margin: 25px 0 0 0;
                    transition: 0.3s;
                    h5 {
                        font-weight: 600;
                        font-size: $font-size-16;
                        color: $color-white;
                        text-transform: uppercase;
                    }
                    &:hover {
                        background: #C53C2F;
                        transition: 0.3s;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* DISTRIBUTEURS
*******************************************************************************/

#section-text-mobile {
    padding: 75px 5%;
    display: none;
    h3 {
        font-weight: bold;
        font-size: $font-size-32;
        color: #356998;
        padding-bottom: 15px;
    }
    p {
        font-weight: normal;
        font-size: $font-size-20;
        color: #356998;
        margin: 0;
        line-height: 1.5;
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 50px 5% 0 5%;
        display: block;
    }
}

.section-form {
    padding: 50px 5%;
    .container {
        width: 100%;
        h3 {
            font-weight: bold;
            font-size: $font-size-32;
            color: #356998;
            padding-bottom: 15px;
            @media screen and (max-width: 1024px) {
                padding-bottom: 5px;
            }
        }
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #356998;
            margin: 0;
            line-height: 1.5;
            @media screen and (max-width: 1400px) {
                font-size: 0.9rem;
            }
        }
        @media screen and (max-width: $size-sm-max) {
            .form-group.col-xs-12.col-sm-12.px0.pt2 .form-group.col-sm-10 {
                width: 100%;
            }
            form .form-group.form-btn {
                width: 100%;
                .col-xs-12.buttons {
                    justify-content: center;
                    .btn-submit {
                        min-width: initial !important;
                    }
                }
            }
            @media screen and (max-width: $size-xs-max) {
                .form-group.text-box.pt2 {
                    display: none;  
                }
                .form-group.col-sm-8.pr0, .form-group.col-xs-12.col-sm-6, .form-group.col-sm-10 {
                    padding: 0;
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 50px 10% 100px 10%;
    h3 {
        font-weight: bold;
        font-size: $font-size-32;
        color: #356998;
        padding-bottom: 15px;
    }
    h5 {
        font-weight: normal;
        font-size: $font-size-18;
        color: #356998;
        line-height: 1.5;
    }
    @media screen and (max-width: $size-sm-max) {
        padding: 50px 10% 100px 10%;
        flex-direction: column;
        text-align: center;
        .center-side {
            padding: 35px 0;
        }
        .jours-heures.flex {
            justify-content: center;
        }
        @media screen and (max-width: $size-xs-max) {
            padding: 50px 5% 75px 5%;
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    

}

@media screen and (max-width: $size-xs-max) {
   

}
